export const headerLinks = [
  {
    title: "发现音乐",
    link: "/discover"
  },
  {
    title: "我的音乐",
    link: "/mine"
  },
  {
    title: "朋友",
    link: "/friend"
  },
  {
    title: "商城",
    link: "https://music.163.com/store/product"
  },
  {
    title: "音乐人",
    link: "https://music.163.com/nmusician/web/index#/"
  },
  {
    title: "下载客户端",
    link: "https://music.163.com/#/download"
  }
]


export const footerLinks = [
  {
    title: "服务条款",
    link: "https://st.music.163.com/official-terms/service"
  },
  {
    title: "隐私政策",
    link: "https://st.music.163.com/official-terms/privacy"
  },
  {
    title: "儿童隐私政策",
    link: "https://st.music.163.com/official-terms/children"
  },
  {
    title: "版权投诉指引",
    link: "https://music.163.com/st/staticdeal/complaints.html"
  },
  {
    title: "意见反馈",
    link: "#"
  }
]

export const footerImages = [
  {
    link: "https://music.163.com/st/userbasic#/auth"
  },
  {
    link: "https://music.163.com/recruit"
  },
  {
    link: "https://music.163.com/web/reward"
  },
  {
    link: "https://music.163.com/uservideo#/plan"
  }
]

// discover中的数据
export const dicoverMenu = [
  {
    title: "推荐",
    link: "/recommend"
  },
  {
    title: "排行榜",
    link: "/ranking"
  },
  {
    title: "歌单",
    link: "/songs"
  },
  {
    title: "主播电台",
    link: "/djradio"
  },
  {
    title: "歌手",
    link: "/artist"
  },
  {
    title: "新碟上架",
    link: "/album"
  },
]

// 热门主播
export const hotRadios = [
  {
      picUrl: "http://p1.music.126.net/H3QxWdf0eUiwmhJvA4vrMQ==/1407374893913311.jpg",
      name:"陈立",
      position:"心理学家、美食家陈立教授",
      url:"/user/home?id=278438485"
  },
  {
      picUrl: "http://p1.music.126.net/y5-sM7tjnxnu_V9LWKgZlw==/7942872001461517.jpg",
      name:"DJ艳秋",
      position:"著名音乐节目主持人",
      url:"/user/home?id=91239965",
  },
  {
      picUrl: "http://p1.music.126.net/6cc6lgOfQTo6ovNnTHPyJg==/3427177769086282.jpg",
      name:"国家大剧院古典音乐频道",
      position:"国家大剧院古典音乐官方",
      url:"/user/home?id=324314596",
  },
  {
      picUrl:  "http://p1.music.126.net/xa1Uxrrn4J0pm_PJwkGYvw==/3130309604335651.jpg",
      name:"谢谢收听",
      position:"南京电台主持人王馨",
      url:"/user/home?id=1611157",
  },
  {
      picUrl: "http://p1.music.126.net/slpd09Tf5Ju82Mv-h8MP4w==/3440371884651965.jpg",
      name:"DJ晓苏",
      position:"独立DJ，CRI环球旅游广播特邀DJ",
      url:"/user/home?id=2313954"
  }
]
